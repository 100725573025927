import { Route } from '../../shared/components/navigation';

enum EmployeeSpace {
  Teacher = 'teachers',
  TeacherAdministrative = 'teacherAdministrative',
  Administrative = 'administrative',
  EmployeeArchived = 'employeeArchived'
}

export const employeeRoutes: Route[] =
  [
    {
      title: 'staffMember.role.teacher',
      path: `/teacher`,
      type: EmployeeSpace.Teacher,
    },
    {
      title: 'staffMember.role.teacher_staff',
      path: `/teacher-administrative`,
      type: EmployeeSpace.TeacherAdministrative,
    },
    {
      title: 'staffMember.role.staff',
      path: `/administrative`,
      type: EmployeeSpace.Administrative,
    },
    {
      title: 'staffMember.archived',
      path: `/employee-archived`,
      type: EmployeeSpace.EmployeeArchived,
    }
  ];
